import React from "react";

const TagStatic = ({ children, tags = [] }) => {
  return (
    <div className="tag-static">
      {tags.map((tag, index) => (
        <span key={index} className="tag">{tag}</span>
      ))}
      {children}
    </div>
  );
};

export default TagStatic;
