import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import Page from "./pages/Page";
import Samansa from "./pages/workSamansa";
import Meditel from "./pages/workMeditel";
import Monorevo from "./pages/workMonorevo";
import Rentme from "./pages/workRentme";
import Noones from "./pages/workNoones";
import Alphanote from "./pages/workAlphanote";
import Penmark from "./pages/workPenmark";
import Buttons from "./DesignSystem/Buttons";
import Inputs from "./DesignSystem/Inputs";
import WorkTemp from "./ui/comp/WorkTemp";
import MobileMenu from "./ui/comp/MobileMenu";
import CustomIcon from "./data/IconCompo";
import BtnDef from "./ui/atom/Btn";
import Hafid from "./pages/hafid";
import BlogPage from "./ui/comp/BlogPage";
import Blog from "./pages/Blog";
import AboutMe from "./pages/AboutMe";

const App = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [scrollpos, setScrollpos] = useState(false);

  useEffect(() => {
    const body = document.querySelector("body");
    const html = document.querySelector("html");

    body.style.height = mobileMenu ? "100vh" : "auto";
    body.style.overflowY = mobileMenu ? "hidden" : "scroll";

    if (!mobileMenu) {
      html.scrollTo({ top: scrollpos, left: 0 });
    }
  }, [mobileMenu, scrollpos]);

  const commonProps = {
    setMobileMenu,
    mobileMenu,
    scrollpos,
    setScrollpos,
  };

  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Page {...commonProps} />} />
          <Route path="home/:section" element={<Page {...commonProps} />} />
          <Route path="/buttons" element={<Buttons />} />
          <Route path="/inputs" element={<Inputs />} />
          <Route path="/worktemp" element={<WorkTemp {...commonProps} />} />
          <Route path="/samansa" element={<Samansa {...commonProps} />} />
          <Route path="/meditel" element={<Meditel {...commonProps} />} />
          <Route path="/monorevo" element={<Monorevo {...commonProps} />} />
          <Route path="/noones" element={<Noones {...commonProps} />} />
          <Route path="/alphanote" element={<Alphanote {...commonProps} />} />
          <Route path="/penmark" element={<Penmark {...commonProps} />} />
          <Route path="/rentme" element={<Rentme {...commonProps} />} />
          <Route path="/about" element={<Rentme {...commonProps} />} />
          <Route path="/me" element={<Hafid />} />
          <Route path="/hafid" element={<Hafid />} />
          <Route path="/blogpage" element={<BlogPage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/aboutme" element={<AboutMe />} />
        </Routes>
        {mobileMenu && (
          <div className="mobile-menu">
            <MobileMenu {...commonProps}>
              <BtnDef className="close" onClick={() => setMobileMenu(false)}>
                <CustomIcon iconName="close" />
              </BtnDef>
            </MobileMenu>
          </div>
        )}
      </BrowserRouter>
    </React.StrictMode>
  );
};

createRoot(document.getElementById("root")).render(<App />);
