import "../styles/main.scss";
import "../styles/designSys.scss";
import "../styles/temp.scss";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../ui/comp/Header";
import Footer from "../ui/comp/Footer";
import BlogPage from "../ui/comp/BlogPage";
import BlogCard from "../ui/comp/BlogCard";

function Blog({ setMobileMenu, mobileMenu, scrollpos, setScrollpos }) {
  const [infoPopupData, setInfoPopupData] = useState(null);
  const { section } = useParams();

  useEffect(() => {
    if (!infoPopupData) return;

    const infoPopup = document.querySelector(".info-popup");
    if (!infoPopup) return;

    infoPopup.style.top = "32px";

    const timerId = setTimeout(() => {
      infoPopup.style.top = "-100px";
      setTimeout(() => setInfoPopupData(null), 500);
    }, infoPopupData.time || 4000);

    return () => clearTimeout(timerId);
  }, [infoPopupData]);

  useEffect(() => {
    if (section) {
      const targetElement = document.querySelector(`.${section}-section`);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - 30,
          behavior: "smooth"
        });
      }
    }
  }, [section]);

  return (
    <div className="blog">
      <Header
        setMobileMenu={setMobileMenu}
        mobileMenu={mobileMenu}
        scrollpos={scrollpos}
        setScrollpos={setScrollpos}
      />
      <section>
        <BlogPage>
          <BlogCard
            image="/path-to-image1.jpg"
            title="Blog Post 1wwwwwww"
            date="2024-01-01"
            writer="John Doe"
            writerPhoto="/path-to-writer-photo1.jpg"
            tags={['Design', 'Development']}
            link="/blog/post-1"
          />
          <BlogCard
            image="/path-to-image2.jpg"
            title="Blog Post Blog Post Blog Post Blog Post Blog Post Blog Post Blog Post Blog Post  Blog Post  Blog Post  Blog Post  Blog Post  Blog Post "
            date="2024-01-02"
            writer="Jane Smith"
            writerPhoto="/path-to-writer-photo2.jpg"
            tags={['UI/UX', 'Technology']}
            link="/blog/post-2"
          />
          <BlogCard
            image="/path-to-image3.jpg"
            title="Blog Post 3"
            date="2024-01-03"
            writer="Bob Johnson"
            writerPhoto="/path-to-writer-photo3.jpg"
            tags={['News', 'Development']}
            link="/blog/post-3"
          />
          <BlogCard
            image="/path-to-image4.jpg"
            title="Blog Post 4"
            date="2024-01-04"
            writer="Alice Brown"
            writerPhoto="/path-to-writer-photo4.jpg"
            tags={['Design', 'Technology']}
            link="/blog/post-4"
          />
          <BlogCard
            image="/path-to-image5.jpg"
            title="Blog Post 5"
            date="2024-01-05"
            writer="Charlie Green"
            writerPhoto="/path-to-writer-photo5.jpg"
            tags={['UI/UX', 'News']}
            link="/blog/post-5"
          />
        </BlogPage>
      </section>
      <Footer />
    </div>
  );
}

export default Blog;
