import React, { useState, useEffect } from 'react';
import BlogCard from './BlogCard';
import Input from '../atom/Input';
import "../../styles/designSys.scss";

const BlogPage = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredChildren, setFilteredChildren] = useState(children);

  const availableTags = ['Design', 'Development', 'UI/UX', 'Technology', 'News'];

  const handleTagClick = (tag) => {
    setSelectedTags(prevTags => 
      prevTags.includes(tag) 
        ? prevTags.filter(t => t !== tag)
        : [...prevTags, tag]
    );
  };

  useEffect(() => {
    const filtered = React.Children.toArray(children).filter(child => {
      if (!child.props) return false;
      
      const searchableFields = [
        child.props.title,
        child.props.date,
        child.props.writer,
        child.props.content,
        ...(child.props.tags || [])
      ];

      const matchesSearch = searchableFields.some(field => 
        field && field.toLowerCase().includes(searchQuery.toLowerCase())
      );
      
      const matchesTags = selectedTags.length === 0 || 
        (child.props.tags && selectedTags.some(tag => child.props.tags.includes(tag)));
      
      return matchesSearch && matchesTags;
    });
    setFilteredChildren(filtered);
  }, [children, searchQuery, selectedTags]);

  return (
    <div className="blogpage">

      <div className="blog_header">
        <h1 className="blog_title">Blog</h1>
        <p className="blog_count">{filteredChildren.length}</p>
      </div>

      <div className="blog_search">
        <Input
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder="Search articles..."
          type="text"
        />
      </div>

      <div className="blog_tags">
        {availableTags.map(tag => (
          <button
            key={tag}
            className={`blog_tag ${selectedTags.includes(tag) ? 'active' : ''}`}
            onClick={() => handleTagClick(tag)}
          >
            {tag}
          </button>
        ))}
      </div>

      <div className="blog_grid">
        {filteredChildren}
      </div>
      
    </div>
  );
};

export default BlogPage;