import React from "react";

const AboutImagesGrid = ({ images, className }) => {
  return (
    <div className={`about-images-grid ${className}`}>
      {images.map((image, index) => (
        <img key={index} src={image} alt={`image ${index + 1}`} />
      ))}
    </div>
  );
};

export default AboutImagesGrid;


