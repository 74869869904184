import React, { useEffect, useState } from "react";

const Section = ({ icon, children, fullRounded = true, id, className }) => {
  return (
    <div className={`about_section ${className || ''}`} id={id}>
      <div className="about_section_box">{children}</div>
    </div>
  );
};

export default Section;
