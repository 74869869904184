import React from "react";
import "../../styles/main.scss";

const AboutList = ({ title, items }) => {
  return (
    <div className="about-list">
      {title && <h3>{title}</h3>}
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <span>{item.title}</span> {item.text}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AboutList;
