import React, { useEffect, useRef } from "react";
import HoverIcon from "../atom/Icon";
import BtnDef from "../atom/Btn";
import { useLocation, Link } from "react-router-dom";

export default function Header({ className, setScrollpos, setMobileMenu, mobileMenu }) {
  const head = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const isDesktopLarge = window.innerWidth > 920;
      const isDesktop = window.innerWidth > 540;
      const transient = (window.innerHeight - window.scrollY) / window.innerHeight;

      const { initialHeight, finalHeight } = getHeights(isDesktopLarge, isDesktop);
      const height = calculateHeight(initialHeight, finalHeight, transient);
      const { boxShadowX, boxShadowY } = calculateBoxShadow(transient);
      const backgroundColorAlpha = Math.max(0, 1 - transient);

      applyStyles(head.current, height, boxShadowX, boxShadowY, backgroundColorAlpha);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  const location = useLocation();

  const scrollToSection = (sectionClass, offset = 0) => {
    const element = document.querySelector(sectionClass);
    if (element) {
      const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: elementTop + offset,
        behavior: "smooth",
      });
    }
  };

  const navigateTo = (path, sectionClass, offset = 0) => {
    if (location.pathname !== "/" && location.pathname !== "/home") {
      window.location.href = path;
    } else {
      scrollToSection(sectionClass, offset);
    }
  };

  return (
    <div className={`header-hero ${className}`} ref={head}>
      <div className="header-logo_box">
        <div className="logo_button logo_button_cont" onClick={() => navigateTo("/", "html", 0)}>
          <HoverIcon className="btn_sec" icon="juusando_logo" iconHover="logo_ani" />
          <span>JUUSANDO!</span>
        </div>
      </div>

      <div className="header-nav_box">
        {["ABOUT", "SERVICE", "PROCESS", "WORKS"].map((item) => (
          <nav key={item} className="header-nav" onClick={() => navigateTo(`/home#${item.toLowerCase()}`, `.${item.toLowerCase()}-section`)}>
            {item}
          </nav>
        ))}
        {/* <Link to="/blog" className="header-nav">BLOG</Link> */}
      </div>

      <div className="header-right_box">
        <div className="btn_sec contact-btn" onClick={() => navigateTo("/home#contact", ".contact-section", -50)}>
          <h5>CONTACT</h5>
          <HoverIcon className="" icon="mail" iconHover="pinjob_ani" />
        </div>
      </div>

      <div className="header-hum_box">
        <BtnDef
          className="btn_sec"
          onClick={() => {
            setScrollpos(window.pageYOffset);
            setMobileMenu(!mobileMenu);
          }}
        >
          <HoverIcon className="" icon="humb" iconHover="humb" />
        </BtnDef>
      </div>
    </div>
  );
}

function getHeights(isDesktopLarge, isDesktop) {
  if (isDesktopLarge) return { initialHeight: 120, finalHeight: 80 };
  if (isDesktop) return { initialHeight: 88, finalHeight: 64 };
  return { initialHeight: 68, finalHeight: 54 };
}

function calculateHeight(initialHeight, finalHeight, transient) {
  const heightDifference = initialHeight - finalHeight;
  return `${Math.max(finalHeight, initialHeight - (1 - transient) * heightDifference)}px`;
}

function calculateBoxShadow(transient) {
  return {
    boxShadowX: Math.min(2, Math.max(0, 2 - transient * 2)),
    boxShadowY: Math.min(2, Math.max(0, 3 - transient * 3)),
  };
}

function applyStyles(element, height, boxShadowX, boxShadowY, backgroundColorAlpha) {
  if (element) {
    element.style.height = height;
    element.style.boxShadow = `0px 0px ${boxShadowX}px ${boxShadowY}px #00000012`;
    element.style.backgroundColor = `rgba(255, 255, 255, ${backgroundColorAlpha})`;
  }
}
