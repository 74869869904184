// import CustomIcon from "../data/IconCompo";
import "../styles/main.scss";
import "../styles/designSys.scss";
import "../styles/temp.scss";
import React, { useEffect, useRef, useState } from "react";

import InputSelectData from "../ui/atom/InputSelectData";
import Header from "../ui/comp/Header";
import SectionCard from "../ui/comp/SectionCard";
import TopSection from "../ui/comp/TopSection";
import SubSectionCard from "../ui/comp/SubSectionCard";
import SubCard from "../ui/comp/SubCard";
import WorkCard from "../ui/comp/WorkCard";
import SloganCrad from "../ui/comp/SloganCard";
import WorkStyleCard from "../ui/comp/WorkStyleCard";
import Footer from "../ui/comp/Footer";
import TeamCard from "../ui/comp/TeamCard";
import ContactUs from "../ui/temp/Contact";
import InfoPopup from "../ui/atom/InfoPopup";
import ContactForm from "../ui/comp/contactForm";
import PopupTemp from "../ui/comp/PopupTemp";
import { useParams } from "react-router-dom";
import BtnDef from "../ui/atom/Btn";
import HoverIcon from "../ui/atom/Icon";
import AboutSectionCard from "../ui/comp/AboutSectionCard";
import AboutInfoCard from "../ui/comp/AboutInfoCard";

function Home({ setMobileMenu, mobileMenu, scrollpos, setScrollpos }) {
  const [infoPopupData, setInfoPopupData] = useState();
  const [successPopup, setSuccessPopup] = useState();
  const { section } = useParams();
  useEffect(() => {
    let infoPopup = document.querySelector(".info-popup");
    let timerId;
    if (infoPopupData && infoPopup) {
      setTimeout(() => {
        infoPopup.style.top = "32px";
      }, 100);

      clearTimeout(timerId);

      timerId = setTimeout(
        () => {
          infoPopup.style.top = "-100px";
          setTimeout(() => {
            setInfoPopupData(false);
          }, 500);
        },
        infoPopupData.time ? infoPopupData.time : 4000
      );
    }

    return () => clearTimeout(timerId);
  }, [infoPopupData]);
  useEffect(() => {
    if (section) {
      document.querySelector("html").scrollTo({
        top: document.querySelector(`.${section}-section`).offsetTop - 30,
        left: 0,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <>
      <div className="home">
        {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HEADER :::::::: */}
        {/* <section className="top-section"/> */}

        <Header
          setMobileMenu={(res) => setMobileMenu(res)}
          mobileMenu={mobileMenu}
          scrollpos={scrollpos}
          setScrollpos={(res) => setScrollpos(res)}
        />

        {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: TOP  :::::::: */}
        <TopSection icon={"illus_00"} />

        {/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: ABOUT US  :::::::: */}
        <section className="orange  about-section">
          <AboutSectionCard
            icon="illus_wirks"
            title="ABOUT US"
            content="<p>
              14年以上の経験を持つプロフェッショナルたちが集まり、さまざまな企業のプロジェクトを成功に導いてきました。
              人間中心設計やデザイン思考を軸に、最適なフレームワークを活用し、美しく直感的なプロダクトやサービスを提供します。<br/><br/>
              ユーザーの課題やビジネス上の問題を解決することを使命とし、クライアントと密接に連携しながらプロジェクトを進行。
              戦略立案から設計、プロトタイプ、ユーザビリティテストに至るまで、デジタルプロセスのあらゆる側面を一貫して担当します。<br/>
              私たちの目標は、ユーザーにとって使いやすい体験を提供するだけでなく、ビジネスに価値を生み出すことです。        
            </p>"
          />
                  </section>
          {/* <AboutInfoCard
            content="
            東京を拠点とするプロダクトデザイナーのハフィドです。<br/>
            14年以上のUXUIの経験を持ち、さまざまな企業のプロジェクトに携わってきました。<br/>
            人間中心設計やデザイン思考の方法論、フレームワークなどを用いて、美しくかつ直感的な製品やサービスを提供し、ユーザーの悩みやビジネス上の問題を解決します。<br/>
            ひとりのメンバーとしてプロジェクトに参加し、クライアントと密接に連携をとりながら、デジタルプロセスのあらゆる側面を担当します。          
            " 
            tags={[
              "BtoC" ,
              "BtoB" ,
              "Health Care" ,
              "Fintech" ,
              "Social Media" ,
              "E-commerce" ,
              "Retail" ,
              "AI" ,
              "Blockchain" ,
              "Logistics" ,
              "Travel" ,
              "Foodtech" ,
              "Gaming" ,
              "IoT" ,
              "Mobility" ,
              "Wearables" ,
           ]} 
           tags2={[
             "A/B Testing" ,
             "Accessibility Design" ,
             "Branding" ,
            "Competitive Analysis" ,
            "Content Strategy" ,
            "Cross-Platform Design" ,
            "Journey Map" ,
            "Persona" ,
            "Data Visualization" ,
            "Design Sprint" ,
            "Design Systems" ,
            "Front-End Collaboration" ,
            "IA" ,
            "Interaction Design" ,
            "Interviews" ,
            "Microinteraction Design" ,
            "Mobile App Design" ,
            "Motion Design" ,
            "Prototyping" ,
            "Responsive Design" ,
            "Survey Design" ,
            "UI Design" ,
            "Usability Testing" ,
            "UX Audit" ,
            "UX Research" ,
            "Visual Design" ,
            "Wireframing"         
          ]} 

         /> */}

        {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: ALL ABOUT :::::::: */}
        {/* <div className="slogan_about_hero">
          <SloganCrad icon={"illus_03"} />
        </div> */}
        {/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: OUR SERVICES ::::::::: */}

        <section className="top service-section">
          <SectionCard
            icon="service_top"
            title="WHAT I DO"
            content="<p>
          ユーザー体験を向上させる手助けをし、専門的なUXUIサポートを提供します。<br/>
            戦略からUXデザインプロセス全体、そしてリリース後の細部に至るまで、クライアントの要件、ユーザーのニーズ、目標に基づいたソリューションを提供します。          
            </p>"
          />
          {/* - - - - - - - - - - - - -  - -SUB CARDS - - - -  - - - - - -  - - - - - -  */}
          <div className="subsection-card-box">
            <SubCard
              icon="service_ux"
              title="UX DESIGN"
              category="WHAT I DO"
              content="<p>
              幅広いUXデザイン手法を知り尽くし、包括的なプロセスで活動しています。<br/>
              広範なリサーチやインタビュー、ペルソナやジャーニーマッピングの開発、ブレインストーミングセッション、デザインスプリント、ワイヤーフレーム、インタラクティブなプロトタイプ、使いやすさテストなどを含みます。<br/>
              これらを用いて、お客様の特定のニーズに合わせたソリューションを提供します。<br/>
              ストラテジー戦略の定義からリリース後の詳細な調整まで、プロジェクト全体を通してサポートを提供します。
                                  </p>"
            />
            <SubCard
              icon="service_design"
              title="UI DESIGN"
              category="WHAT I DO"
              content="<p>
              ディテールを見落とさず、タイポグラフィ、カラーセオリー、レイアウトの原則を組み込み、プロダクトコンセプト、ユーザーのニーズ、ビジネス目標とマッチするデザインを作り上げます。<br/>
              ハイレベルなUIデザインやブランディング、オリジナルのアイコノグラフィー、イラスト、ダイナミックなUIアニメーションなどを提供し、競争の激しい市場でクライアントのプロダクトを際立たせます。<br/>
              一貫性と整合性への取り組みはすべての要素に及び、包括的なUIデザインのモックアップ、バランスの取れたデザインシステム、スタイルガイド、およびスムーズで完璧なプロダクト開発のために必要なすべての成果物を作成し、クライアントの開発チームが必要な要素を手に入れることを保証します。             
              </p>"
            />
          </div>
          {/* - - - - - - - - - - - - -  - - - - - -  - - - - - -  - - - - - -  */}
        </section>

        {/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: OUR PROCESS::::: */}
        <section className="top process-section">
          <SectionCard
            icon="Process_top"
            title="THE PROCESS"
            content="<p>
            さまざまな新興企業や大企業との仕事から得たスキル知識を共有するプロジェクトのメンバーとして、創設者、プロジェクトマネージャー/所有者、およびすべてのプロジェクトチームと緊密に協力しています。
                      </p>"
          />
          {/* - - - - - - - - - - - - -  - -SUB CARDS - - - -  - - - - - -  - - - - - -  */}
          <div className="subsection-card-box">
            <SubCard
              icon="Process_discover"
              title="DISCOVER"
              category="Our Process"
              content="<p>
              このフェーズでは、クライアントのストーリーや戦略的目標を理解するために包括的な調査を行います。インタビューや調査、競合分析など、さまざまなUXデザイン手法を活用します。<br/>
              インタビューを通じて、ターゲットユーザーのニーズや課題、ビジネス目標について深く掘り下げます。<br/>
              競合分析により、市場の状況を把握し、機会やギャップを特定します。<br/>
              調査は、ユーザーの行動や市場トレンド、技術の進歩など、さまざまな方法で行われます。<br/>
              これらの手法を組み合わせることで、ユーザーのニーズや好み、クライアントおよびビジネスの目標について包括的に理解し、設計ソリューションの明確な方向性を確立します。             
               </p>"
              tags={[
                "Interviews",
                "Competitive Analysis",
                "Persona",
                "Task Analysis",
                "User Story Mapping",
                "Empathy Map",
                "Data Analysis",
                "User Journey Mapping",
              ]}
            />
            <SubCard
              icon="Process_define"
              title="DEFINE"
              category="The Process"
              content="<p>
              Discoveryで得た洞察をもとに、Defineステップでは調査結果を具体的な洞察に結集します。<br/>
              問題を明確にし、Personaを開発することで、デザインソリューションがユーザーセンターになるようにします。<br/>
              ブレストセッションを通じてアイデアを生み出し、カスタマージャーニーマップやワークフローを活用してプロセスを最適化します。<br/>
              IAを整理し、ワイヤーフレームでソリューションのレイアウトを視覚化します。<br/>
              厳格なテストとデザインスプリントを通じて、コンセプトを検証し、デザインを改善します。<br/>
              これにより、ソリューションが異なるユーザーセグメントの多様なニーズに適合し、特定の課題に効果的に対処できるようになります。
                            </p>"
              tags={[
                "Wireframe",
                "Design Sprints",
                "Stakeholder Workshops",
                "User Story",
                "Customer Journey",
                "User Flow",
                "Brainstorming",
                "Use Case",
              ]}
            />
            <SubCard
              icon="Process_design"
              title="DESIGN"
              category="The Process"
              content="<p>

              デザインフェーズでは、各UIコンポーネントを細心の注意を払って作成し、使いやすさを向上させ、視覚的魅力を高め、シームレスで魅力的なユーザーエクスペリエンスを実現しています。<br/>
              ピクセルパーフェクトなインターフェースを作成することで、ユーザーを喜ばせ、機能性をシームレスに融合させることにコミットしています。<br/>
              オリジナリティに重点を置き、デザインがプロダクトコンセプトとターゲットと調和し、ユニークさを提供するよう心がけています。<br/>
              カラースキーム、タイポグラフィ、アイコノグラフィ、イメージなどを統一的に統合することで、統一されたユーザーエクスペリエンスを実現しています。<br/>
              マイクロインタラクションはユーザーエンゲージメントを高め、包括的なデザインシステムにより作成とメンテナンスを最適化し、一貫性と効率性を提供します。<br/>
              デバイス間で一貫性とアクセシビリティを重視し、すべてのユーザーにとって楽しいエクスペリエンスを提供します。
                            </p>"
              tags={[
                "Branding",
                "Style Guide",
                "Prototype",
                "Icon Design",
                "Motion Design",
                "User Test Protocols",
              ]}
            />
            <SubCard
              icon="Process_deliver"
              title="DELIVER"
              category="The Process"
              content="<p>
              デザインが最終的に決定されたら、高度なモックアップや詳細なデザイン仕様を作成します。<br/>
              これらの資料は、開発者に明確な指針を提供し、最終的なプロダクトがユーザーのニーズと技術的要件の両方を満たすことを確認します。<br/>
              また、開発チームとの緊密な連携を図りながら、デザインシステム、スタイルガイド、およびドキュメンテーションを開発し、プロトタイプのデモンストレーションを行います。<br/>
              納品プロセス全体を通じて、デザインレビューを実施し、納品後の微調整にも対応するサポートを提供します。<br/>
              さらに、リリースに関連するデザインタスク、例えばプロモーション資料やリリース前のデザインなどを手伝い、成功したプロダクトのローンチをサポートします。
                             </p>"
              tags={[
                "Creative Direction",
                "Design Material",
                "Design System",
                "Presentation",
                "Style Guide",
                "Design Deliverables",
                "Project Management",
                "Animation Guides",
              ]}
            />
          </div>
          {/* - - - - - - - - - - - - -  - - - - - -  - - - - - -  - - - - - -  */}
        </section>
        {/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: OUR WORKS  ::::::::: */}

        <section className="top works-section">
          <SectionCard
            icon="work_top"
            title="MY WORKS"
            content="<p>
            さまざまな新興企業や大企業との仕事から得たスキル知識を共有するプロジェクトのメンバーとして、創設者、プロジェクトマネージャー/所有者、およびすべてのプロジェクトチームと緊密に連携しています。
                      </p>"
          />
          <div className="subsection-card-box">
            <WorkCard
              link="/meditel"
              className="work-01"
              image="/meditel_00@2x.png"
              title="Meditel"
              category="HEALTHCARE"
              content="<p>
              患者がスマートフォンアプリを通じて、オンライン診察予約や医師とのテレビ電話による診察、クレジットカードでの診察料の支払い、そして健康データの日々の記録など、様々なサービスを提供しています。<br/>
              また、医師はウェブブラウザを介して、健康に関するアドバイスやテレビ電話による診察などを行うことができ、これにより患者と医師の間でオンライン診療がより身近で簡単に利用できるようになります。                   
               </p>"
              tags={[
                "Interview",
                "Research",
                "Persona",
                "Journey Map",
                "Design Sprint",
                "Wire-frame",
                "Prototype",
                "Usability Test",
              ]}
            />
            <WorkCard
              link="/samansa"
              className="work-02"
              image="/samansa_00@2x.png"
              title="Samansa"
              category="Video Streaming"
              content="<p>
              世界中のクリエイターと直接契約を交わし、様々なショート映画を配信している映像配信サービスです。<br/>
              一作品ごとにクリエイターと契約を結んでおり、すべての作品が1〜20分程度の短編です。<br/>
              移動時間やスキマ時間など、好きな時間に好きな場所でハイクオリティな映画を楽しむことができます。
                                  </p>"
              tags={["Wire-frame", "Usability Test"]}
            />
            <WorkCard
              link="/noones"
              className="work-02"
              image="/work_photo_03.png"
              title="Noones"
              category="Crypto"
              content="<p>
              人々を世界中の会話(チャット)と金融システム(支払い)に結びつけ、力を与える金融コミュニケーションスーパーアプリです。 
              ビットコインウォレットを使って価値を保存し、誰でも自由にメッセージを送信したり、マーケットプレイスで約250の支払い方法で取引したり、ピアツーピアで支払いを行ったりすることができます。
                    </p>"
              tags={[
                "Interview",
                "Research",
                "Persona",
                "Journey Map",
                "Design Sprint",
                "Wire-frame",
                "Usability Test",
              ]}
            />
            <WorkCard
              link="/penmark"
              className="work-03"
              image="/penmark_top@2x.png"
              title="Penmark"
              category="Education Tools"
              content="<p>
              大学生専用の履修管理SNSアプリです。 <br/>
              大学生活に必要な時間割管理から学内コミュニケーションまで、公式シラバスを元にした時間割作成やトークルーム機能などを提供し、情報収集を容易にし、より充実した大学生活を実現します。                   
               </p>"
              tags={[
                "Interview",
                "Research",
                "Persona",
                "Information Achitecture",
                "Journey Map",
                "Design Sprint",
                "Wire-frame",
                "Prototype",
                "Usability Test",
              ]}
            />
            <WorkCard
              link="/rentme"
              className="work-04"
              image="/rentme_00@2x.png"
              title="Rent Me"
              category="Real Estate"
              content="<p>
              伝統的な不動産エージェントを必要とせず、日本での家の賃貸プロセスをスムーズにする革新的なプロジェクトです。  
              この時代には、仲介手数料がゼロであることが期待されています。
              ユーザーが使いやすいモバイルアプリを通じて家を探し、訪問し、契約することができるサービスを先駆けとして開発しています。
                    </p>"
              tags={[
                "Research",
                "Customer Journey",
                "Userflow",
                "Persona",
                "Wireframe",
                "Prototype",
                "Usability Test",
                "その他",
              ]}
            />
            <WorkCard
              link="/monorevo"
              className="work-05"
              image="/monorevo_00@2x.png"
              title="Monorevo"
              category="Project Manager - toB"
              content="<p>

              製造業に特化したクラウドアプリケーションで、工場の製造プロセスを容易に可視化できます。
              特に、「少量多品種」×「短納期」が要求される製造業現場において、DXを駆使して効率を飛躍的に向上させるサービスです。<br/>
              工程管理、在庫管理、受発注管理など、あらゆる業務を統合管理でき、製造業現場の円滑な運営を支援します。
                    </p>"
              tags={[
                "Research",
                "Customer Journey",
                "Userflow",
                "Wireframe",
                "Prototype",
                "Usability Test",
                "その他",
              ]}
            />
            <WorkCard
              link="/alphanote"
              className="work-06"
              image="/alphanote_00@2x.png"
              title="Alphanote"
              category="Music Community"
              content="<p>
              革新的な無料の音楽ストリーミングプラットフォームであり、独立したミュージシャンを支援し、地域の音楽の需要に応えることを目指しています。<br/>
              この新しい音楽ストリーミングプラットフォームは、音楽クリエイター、リスナー、ファンを最高の音楽体験でつなぎ、音楽業界を再活性化することを目指しています。
                    </p>"
              tags={[
                "Research",
                "Persona",
                "Customer Journey",
                "Userflow",
                "Wireframe",
                "Prototype",
                "Usability Test",
                "その他",
              ]}
            />
          </div>
        </section>
        {/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: CONTACT ::::::::: */}

        <section className="top contact-hero contact-section">
          <SectionCard
            className={"section-top-hero-2"}
            title="CONTACT US"
            content="<p>
            弊社のサービスやワークスタイルに関するご質問、UXUIデザインに関するご相談や見積もり、
            またはその他のご要望がございましたら、どうぞお気軽にお問い合わせください。<br/>
            お問い合わせ内容や件数に応じて、迅速にご対応いたします。         
             </p>"
          />
          <ContactForm
            setInfoPopupData={(res) => {
              setInfoPopupData(res);
            }}
            setSuccessPopup={(res) => {
              setSuccessPopup(res);
            }}
          />
        </section>
        {/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: FOOTER ::::::::: */}
        <Footer />
        {/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */}
      </div>
      {infoPopupData && (
        <InfoPopup
          text={infoPopupData.text}
          type={infoPopupData.type}
          iconName={infoPopupData.iconName}
          // Make icon2 optional
          onClose={() => {
            document.querySelector(".info-popup").style.top = "-100px";
            setTimeout(() => {
              setInfoPopupData(false);
            }, 500);
          }}
        />
      )}
      {successPopup && (
        <div className="popup-cont">
          <PopupTemp
            text={successPopup?.text}
            title={successPopup?.title}
            showImage={true}
            showText={true}
            showTitle={true}
            onClose={() => {
              setSuccessPopup(false);
            }}
          />
        </div>
      )}
    </>
  );
}

export default Home;
