import React from 'react';
import { Link } from 'react-router-dom';
import "../../styles/designSys.scss";


const BlogCard = ({ image, title, date, writer, writerPhoto, tags, link }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  return (
    <Link to={link} className="blog_card">
      <div className="blog_card_image_container">
        <img src={image} alt={title} className="blog_card_image" />
      </div>
      <div className="blog_card_content">
        <h3 className="blog_card_title">
          {title}
          <div className="blog_card_tags">
          {tags.slice(0, 2).map((tag, index) => (
            <span key={index} className="blog-card_tag">
              {tag}
            </span>
          ))}
        </div>
        </h3>
        
        <div className="blog_card_writer">
          <span className="blog_card_writer_name">
          <img src={writerPhoto} alt={writer} className="blog_card_writer_photo" />
            {writer}
          </span>
          <p className="blog_card_date">{formatDate(date)}</p>

        </div>

      </div>
    </Link>
  );
};

export default BlogCard;
