import React from "react";

const AboutImages = ({ image01, image02 }) => {
  return (
    <picture className="about-images">
      <source media="(max-width: 960px)" srcSet={image01} />
      <source media="(min-width: 961px)" srcSet={image02} />
      <img src={image02} alt="About" />
    </picture>
  );
};

export default AboutImages;
