import "../styles/main.scss";
import React, { useEffect } from "react";
import AboutTag from "../ui/atom/AboutTag";
import Section from "../ui/atom/Section";
import AboutList from "../ui/atom/AboutList";
import AboutImages from "../ui/atom/AboutImages";
import TagStatic from "../ui/atom/TagStatic";
import AboutImagesGrid from "../ui/atom/AboutImagesGrid";

function AboutMe({ }) {

  return (
    <div className="aboutme">
      {/* - - - - - - - - - - - -  */}
{/* 
      <Section className="about_menu">
            <AboutTag
              tags={{
                categories: {
                  items: [
                    "Process",
                    "Industry",
                    "Strategy",
                    "Methods",
                    "Design",
                    "Wireframe",
                    "Prototype",
                    "Branding",
                    "Interaction",
                    "Education",
                    "Tools"
                  ],
                  link: null
                }
              }}
            />
          </Section> */}


      <div className="aboutme_body">
        <div class="aboutme_hiro">
          {/* - - - - - - - - - - - - */}

          <Section>
            <div class="section_box">
              <div class="avatar">
                <img class="avatar_img" src="Orange---Avatar.png" loading="lazy" width="180" />
                <div class="name">
                  はふぃど<br />ブルヒム
                </div>
                <div class="name dark">
                  プロダクト<br />デザイナー
                </div>
              </div>
            </div>
          </Section>

 
          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - */}
          <Section id="process">
            <div class="section_box">
              <div class="text_box">
                <div class="text_box_title">
                  UX DESIGN PROCESS!
                </div>
                <div class="text_box_text">
                  <p>既存のプロジェクトの更新や新規開発において、「UXデザインプロセス」を活用しています。UXデザインプロセスのステップや内容、方法、ツールは、プロジェクトの種類、ターゲットユーザー、クライアントのニーズ、予算、スケジュール、リソースに応じて異なります。</p>
                  <br />
                  <p>プロジェクトの要件や特性に合わせて、UXデザインプロセスを柔軟にカスタマイズし、主要な構造と機能を保ちながら適応させることが重要です。これにより、プロジェクト状況やクライアントのニーズ・要件に応じた最適なデザインが可能となります。</p>
                </div>
                <AboutImages
                  image01="Process-1.png"
                  image02="Process.png"
                />
              </div>
            </div>
          </Section>

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - */}
          <Section id="industry">
            <div class="section_box">
              <div class="text_box">
                <div class="text_box_title">
                  INDUSTRY
                </div>
                <div class="text_box_text">
                  <p>
                    2006年にウェブデザイナーおよびクリエイティブディレクターとしてキャリアをスタートしました。これまでにUIおよびUXデザインのスキルを磨き、2010年以降はこれらの分野により焦点を当てたプロジェクトに取り組んできました。
                  </p>
                  <br />
                  <p>
                    業界での16年の経験を通じて、個人プロジェクトから大企業、数多くのスタートアップに至るまで、幅広いプロジェクトに携わってきました。特に、ヘルスケア、フィンテック、テクノロジーなどさまざまな業界でのプロジェクトに取り組み、クライアントの独自のニーズを理解し、それに合わせたデザインアプローチを適用する貴重な経験を積んできました
                  </p>
                </div>
                <TagStatic
                  tags={[
                    "フードテック",
                    "LP",
                    "ヘルスケア",
                    "スタートアップ",
                    "エドテック",
                    "Eコマース",
                    "MVP",
                    "ユーティリティ",
                    "ビューティー",
                    "IT",
                    "IoT",
                    "toC",
                    "toB",
                    "SaaS",
                    "不動産",
                    "ゲーム",
                    "製造業",
                    "フィンテック",
                    "エンターテインメント",
                    "eスポーツ",
                    "小売"
                  ]}
                />
              </div>
            </div>
          </Section>

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - */}
          <Section id="strategy">
            <div class="section_box">
              <div class="text_box">
                <div class="text_box_title">
                  STRATEGY
                </div>
                <div class="text_box_text">
                  様々なプロジェクトでの長年の経験を通じて、異なる側面や要件が最終的なプロダクトの品質に大きな影響を与えることを理解しました。そのため、クライアントが自身のプロジェクトのコンセプト、ターゲット市場、およびユーザーを深く理解し、プロジェクトに適した計画、ロードマップ、戦略を策定するためのスキルと経験を習得しました。
                  <br /> <br/>
                  戦略立案に関する私のスキルと経験には、以下が含まれます : SWOT/TOWS分析、MVV (ミッション、ビジョン、バリュー) ステートメントの開発、アクションプランの作成、競合分析、主要パフォーマンス指標（KPI）の特定、SMART目標の設定、プロジェクト目標の定義など...               
                  </div>
                これらのスキルやツールを活用し、クライアントが目標に合わせた包括的で効果的な戦略計画を開発し、成功の可能性を最大化できるようサポートしています。
              </div>
              <TagStatic
                tags={[
                  "リサーチ（定量調査/定性調査）",
                  "インタビュー",
                  "ペルソナ",
                  "SWOT/TOWS分析",
                  "ジャーニーマップ",
                  "共感マップ",
                  "ストーリーボード",
                  "Crazy Eight",
                  "マインドマッピング",
                  "ユーザビリティテスト",
                  "ユーザー観察",
                  "A/Bテスティング",
                  "ユーザーフロー",
                  "UX監査",
                  "ブレインストーミング",
                  "ワイヤフレーム",
                  "プロトタイプ",
                  "KPI分析",
                  "デザインスプリント",
                ]}
              />
            </div>
          </Section>

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - */}
          <Section id="methods">
            <div class="section_box">
              <div class="text_box">
                <div class="text_box_title">
                  UX METHODS & TOOLS
                </div>
                <div class="text_box_text">
                  「UXデザインプロセス」の一般的な方法とツールに熟達しており、計画、準備、実行、分析の4つのステップを通じて適用しています。 <br/>
                  プロジェクトのニーズ、スケジュール、リソース、予算に応じて、「UXデザインプロセス」の各段階で使用される技術やツールは異なる場合があります。 <br/>
                  また、リソース、時間、予算が限られている場合は、これらの方法の軽量版を使用することもあります
                  以下は、一般的に使用される方法の例です：
                </div>
              </div>
              <AboutList
                title="Meditel"
                items={[
                  { title: "クライアント", text: "NTT DOCOMO" },
                  { title: "プロジェクト", text: "医療サービス・アプリ、ウェブサービスサイト、管理システム画面、LP、ブランディング、プロモーションデザイン..." },
                  { title: "使用UXメソッド", text: "リサーチ、スティックホルダーインタビュー、ユーザーインタビュー、デザインスプリント、ワイヤフレーム、プロトタイプ、ユーザーテスティング..." },
                ]}
              />
              <AboutImages
                image01="meditel-photo-ver.png"
                image02="meditel-photo-hor.png"
              />

              <AboutList
                title="PENMARK"
                items={[
                  { title: "クライアント", text: "Penmark株式会社" },
                  { title: "プロジェクト", text: "大学生向けサービス / 時間割り、コミュニティ、チャット、シラバス、バイト、部屋探し、プロモーションデザイン..." },
                  { title: "使用UXメソッド", text: "リサーチ、スティックホルダーインタビュー、SWOT/TOWS分析、KPI分析、ユーザーインタビュー、デザインスプリント、ワイヤフレーム、プロトタイプ、ユーザーテスティング..." },
                ]}
              />
              <AboutImages
                image01="penmark-photo-ver.png"
                image02="penmark-photo-hor.png"
              />

            </div>
          </Section>

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - */}
          <Section id="design">
            <div class="section_box">
              <div class="text_box">
                <div class="text_box_title">
                  UI DESIGN
                </div>
                <div class="text_box_text">
                  16年の経験とデザインへの鋭い感覚を持っており、ビジュアル的に魅力的かつ直感的に使用できるピクセルパーフェクトなインターフェースを作成できます。
                  <br/>
                  取り組む技術やツールについて深い理解を持ち、開発者や他のチームメンバーと効果的にコミュニケーションを取ることができます。 <br/>
                  スタートアップ企業や大手企業のB2CプロジェクトやB2Bサービスに取り組む場合でも、ユーザーのニーズとビジネスまたは組織の目標をバランス良く考慮し、両方の要件を満たすインターフェースを提供します。                </div>
              </div>
            </div>
          </Section>

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - */}
          <Section id="wireframe">
            <div class="section_box">
              <div class="text_box">
                <div class="text_box_title">
                  WIREFRAME
                </div>
                <div class="text_box_text">
                  アイデアや仮説を検証するために、ワイヤーフレームを作成するだけでなく、ユーザーに実際に体験してもらうためのインタラクティブなワイヤーフレームも作成します。これにより、有益なフィードバックを得るとともに、アイデアの検証を行うことができます。
                  <br/> <br/>
                  アイデアやソリューションを可視化し、ステークホルダーが容易に意思決定できるよう、より理解・吸収しやすくするためにワイヤーフレームを作成します。
                  それらのアイデアやソリューションをユーザーとテストする際には、PrincipleやProtoPieなどのツールを使ったインタラクティブなワイヤーフレームを作成します。               
                   </div>
              </div>
            </div>
            <AboutImages
                image02="wireframe01.gif"
              />
            <AboutImages
                image02="wireframe02.gif"
              />
            <AboutImages
                image02="wireframe03.png"
              />
            <AboutImages
                image02="wireframe04.png" 
              />


          </Section>

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - */}
          <Section id="prototype">
            <div class="section_box">
              <div class="text_box">
                <div class="text_box_title">
                  PROTOTYPE
                </div>
                <div class="text_box_text">
                  基本的に、UIデザインを完成させた後にプロトタイプを作成し、開発が始まる前にアプリのユーザーエクスペリエンスと機能をテストして検証します。<br/>
                  プロトタイピングにより、元のUIデザインでは明らかにならなかったデザイン上の問題が明らかになります。<br/>
                  アプリへのユーザーのインタラクションを効果的にシミュレートするために、プロトタイプが開発後の最終のプロダクトと同様に見えるように機能することを確認します。ProtoPie、Principle、SVGアニメーションなどのツールを使用します。
                  <br/>
                  これらのツールを使用することで、ユーザーエクスペリエンスを正確に反映したインタラクティブなプロトタイプを作成することができます。<br/>
                  また、異なるシナリオやユーザーフローをテストして、開発が始まる前に潜在的な問題を特定することもできます。<br/>
                  これにより、長期的に時間とリソースを節約することができます。<br/>
                  全体的に、プロトタイピングは、最終のプロダクトがユーザーのニーズや期待に応えることを確認するためのデザインプロセスにおいて不可欠なステップです。<br/>
                  ユーザーはこれを操作して、最終的なプロダクトとまったく同じ体験をすることができます。これにより、プロトタイプから得られる洞察が正確かつ有用であることが保証されます。                </div>
              </div>
            </div>
          </Section>

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - */}
          <Section  id="branding">
            <div class="section_box">
              <div class="text_box">
                <div class="text_box_title">
                  BRANDING
                </div>
                <div class="text_box_text">
                  ブランディングとデザインの専門知識を生かし、混雑した市場で際立つブランドを作ることができます。強いブランドは、顧客に共感する独自で記憶に残るイメージを作り出します。<br/>

                  ブランド開発とグラフィックデザインの豊富な知識をもとに、クライアントがビジネス目標に合わせて一貫した、魅力的で効果的なブランドアイデンティティを作り上げ、その戦略的なアライメントを実現するお手伝いができます。<br/><br/>

                  MVV戦略、ロゴデザイン、命名、カラー、ブランドトーン&マナー、アイコン、オリジナルイラストなどのブランディングサービスを提供しています。<br/>
                  既存のブランドの再ブランディングや、ニーズに合わせてブランドを新たに構築することもできます。               
                   </div>
              </div>
            </div>
            <AboutImagesGrid
                images={[
                  "logo01.png",
                  "logo02.png",
                  "logo03.png",
                  "logo04.png",
                  "logo05.png",
                  "logo06.gif",
                  "logo07.png",
                  "logo08.gif",
                ]}
              />
          </Section>

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - */}
          <Section  id="interaction">
            <div class="section_box">
              <div class="text_box">
                <div class="text_box_title">
                  INTERACTION
                </div>
                <div class="text_box_text">
                  ユーザーの行動について深い理解を持ち、ユーザーに対する強い共感力を持っています。ユーザーのニーズを予測し、直感的で使いやすいインターフェースを設計することができます。<br/>
                  インタラクションやマイクロインタラクション、その他のビジュアル要素を作成するスキルを持っています。<br/>
                  <br/>
                  これらの要素は、ユーザー体験を向上させることができます。ProtoPie、Principle、Figma、After Effects、SVGアニメーションツールなど、さまざまなインタラクションデザインツールに熟練しています。<br/>
                  これにより、チームやステークホルダーと簡単に共有できる高品質なインタラクションやアニメーションを作成することができます。                </div>
              </div>
            </div>
            <AboutImagesGrid
            cassName="grid"
                images={[
                  "intra01.gif",
                  "intra02.gif",
                  "intra03.gif",
                  "intra08.gif",
                  "intra04.gif",
                  "intra11.gif",
                  "intra09.gif",
                  "intra05.gif",
                  "intra06.gif",
                  "intra07.gif",
                  "intra10.gif",
                ]}
              />
          </Section>

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - */}
          <Section  id="education">
            <div class="section_box">
              <div class="text_box">
                <div class="text_box_title">
                  EDUCATION
                </div>
                <div class="text_box_text">
                  2013年にUXUIデザイナーのコミュニティを立ち上げ、最新のUXとUIデザインに関する情報、スキル、知識の共有を目指しました。<br/>
                  UXUIデザイナー向けのサーボスも作成し、UUD.ioというウェブサイトでデザイナー向けのリソース、サービス、情報を提供しています。<br/><br/>

                  それ以来、多くのUX/UIデザインのイベント、ワークショップ、コースを企画・参加し、またデザイン専門学校での教鞭を執る経験も積みました。<br/>
                  このため、必要なスキル、知識、経験を持ち、異なるバックグラウンドを持つ聴衆と共に小規模なワークショップから大規模なワークショップまで進行することができます。<br/><br/>

                  また、教育能力により、すべてのUXデザインの方法、ツール、アクティビティを理解し、クライアントチームに肯定的な影響を与えるために最良の方法で実行できます。               
                </div>
              </div>
            </div>
            <AboutList
                title="UUD (UXUI Designer)設立"
                items={[
                  {  text: "IT業界向け（特にUXUIデザイン業界）" },
                  { text: "世界のデザインの情報を提供・共有、UXUIデザイン知識" },
                  { title: "活動", text: "イベント、ワークショップ、デザイン講座、交流会... 法人向け研修（UXUIデザイン）" },
                ]}
              />
            <AboutImages
                image01="uud01.png"
                image02="uud02.png"
              />
          </Section>

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - */}
          <Section  id="tools">
            <div class="section_box">
              <div class="text_box">
                <div class="text_box_title">
                  TOOLS
                </div>
                <div class="text_box_text">
                  業界の最新トレンドと進歩に情熱を持ち、デザインワークフローを改善するために常に新しいツールやソフトウェアを学ぶことを熱望しています。<br/>
                  様々なツールを使うことで、より多目的なデザイナーになるだけでなく、それぞれのツールの利点と欠点をより深く理解できると信じています。<br/>

                  UIデザインにはFigmaやSketchを、アニメーションやインタラクションデザインにはProtopieやPrinciple、After Effectsなど幅広いツールを使うことで、より魅力的なデザインを生み出すことができます。<br/>
                  また、マイクロインタラクションにはRiveを使うこともあります。これらのツールは、魅力的な体験を作り上げるために非常に強力だと感じています。<br/>

                  日々のルーティンの管理にはユーティリティを活用し、タスクを効率的に管理することで、より密接なクライアントとのコミュニケーションやコラボレーションが可能になります。<br/>
                  また、仕事に楽しさや創造性を加える小さなユーティリティを使うことも好きです。このアプローチは効率を高めるだけでなく、プロセスを楽しくするのに大いに役立ちます。               
                   </div>
              </div>
            </div>
            <AboutImagesGrid
            className="grid"
                images={[
                  "logo-tools01.svg",
                  "logo-tools02.svg",
                  "logo-tools03.svg",
                  "logo-tools04.svg",
                  "logo-tools05.svg",
                  "logo-tools06.svg",
                  "logo-tools07.svg",
                  "logo-tools08.svg",
                  "logo-tools09.svg",
                  "logo-tools10.svg",
                  "logo-tools11.svg",
                  "logo-tools12.svg",
                  "logo-tools13.svg",
                  "logo-tools14.svg",
                  "logo-tools15.svg",
                  "logo-tools16.svg",
                  "logo-tools17.svg",
                ]}
              />
          </Section>

          {/* - - - - - - - - - - - - */}
        </div>
      </div>


      {/* - - - - - - - - - - - - */}
    </div>
  );
}

export default AboutMe;
